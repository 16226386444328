import React from "react"

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import { Helmet } from "react-helmet";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


import WachbueroLogo from '../images/Logo.svg';

import firebase from "gatsby-plugin-firebase"

let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);

theme.typography.h2 = {
  fontSize: '1.25rem'
};

theme.typography.h1 = {
  fontSize: '2rem',

  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
  },
};



export default function Home() {

  React.useEffect(() => {
    firebase.analytics().logEvent('app_rendered');
  }, [])


  const data = useStaticQuery(graphql`
    query {
      phonefile: file(relativePath: { eq: "iphone-12--product-red@2x.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(maxHeight: 902, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Helmet defer={false}>
          <title>Wachbüro | Ihre digitale Feuerwache</title>
          <link rel="canonical" href="http://www.wachbuero.app" />
          <meta name="description" content="Wachbüro ist die ideale Software as a Service Lösung zur Digitalisierung Ihrer Feuerwache. Intuitive Bedienung. Effiziente Performance. Jetzt mehr erfahren!" />
        </Helmet>
        <Grid container>
          <Grid item xs={6}>
            <img
              src={WachbueroLogo}
              alt="Wachbüro Logo" />
          </Grid>
          <Grid item xs={6}>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }} spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h2" style={{color: "#CE2721", fontWeight: "bold"}}>DIGITALE FEUERWACHE</Typography>
            <Typography variant="h1" gutterBottom style={{fontWeight: "bold"}}>Wir digitalisieren Ihre Feuerwache</Typography>
            <Typography variant="body1">Zettel und Stift für die Verwaltung Ihrer Feuerwache gehören der Vergangenheit an. Wir helfen Ihnen mit unserer SaaS Lösung Ihre Prozesse zu digitalisieren.</Typography>
            <Button style={{backgroundColor: "#CE2721", color:"white", marginTop: 40}} variant="contained" onClick={() => window.location.href='mailto:steven@wachbuero.app?subject=Informationen zur Software'}>Jetzt Informationen einholen</Button>
          </Grid>
          <Grid md={3} />
          <Grid item xs={8} md={4}>
            <Img
              fluid={data.phonefile.childImageSharp.fluid}
              alt="Wachbüro App in einem iPhone"
              fadeIn={true}
              loading="eager"
            />
          </Grid>
        </Grid>
        <Grid container wrap="wrap-reverse">
          <Grid item xs={12}>
            <Typography variant="caption">© Copyright 2020 <b>Wachbüro App</b>.<br />All rights reserved.</Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
